<template>
  <div style="margin: -14px -15px ;min-height: 2600px;">
    <a-spin :spinning="spinning">
      <!--    病例审核管理-->
      <!--    <div class="review-title">病例审核管理</div>-->
      <!--    医生信息-->
      <div class="review-doc">
        <div class="review-doc-title">医生信息</div>
        <div class="review-doc-content">
          <div class="review-doc-content-font">姓名 : {{caseDetail.memberName}}</div>
          <div class="review-doc-content-font">医院 : {{ caseDetail.memberHospitalName }}</div>
          <div class="review-doc-content-font">上传时间 : {{caseDetail.created_time}}</div>
        </div>
      </div>
      <!--    患者建档信息-->
      <div class="review-huan">
        <div class="review-huan-title">
          <div class="review-huan-title-huanzhe">患者建档信息</div>
          <div>
            <a-button type="primary" @click="preservation">保存患者信息</a-button>
          </div>
        </div>
        <div class="review-huan-content">
          <div class="review-huan-content-font">
            <div>性别 : </div>
            <div style="margin-left: 15px">
              <a-radio-group
                  v-model="dataForm.patientSex"
                  :options="Sex"
                  @change="onChange" />
            </div>
          </div>
          <div class="review-huan-content-font-two">
            <!--          <div>年龄 :</div><div> {{caseDetail.patientAge}}</div>-->
            <div>年龄 :</div>
            <div style="margin-left: 5px">{{dataForm.patientAge}}</div>
            <div style="margin-left: 15px">
              <a-date-picker
                  placeholder="请选择性出生日期"
                  :format="dateFormatList"
                  @change="ageChange" /></div>
          </div>
        </div>
      </div>
      <!--    中间上传照片部分-->
      <div class="review-content" style="margin-bottom: 50px">
        <!--      图片信息-->
        <div class="review-content-left">
          <div class="review-content-left-title">图片信息</div>
          <div class="review-content-left-font" style="margin-top: 30px">患者图片 ({{  lenghtImage }}张)</div>
          <div style="height:1500px;width:100%;overflow:auto;">
            <div style="width: 100%" v-for="(item,index) in  dataForm.coding_image_list" :key="index">
              <img
                  style="width: 95%;height: auto;margin: 10px 10px"
                  :src="dataForm.coding_image_list[index]"
              />
            </div>
          </div>
        </div>
        <!--      问卷信息-->
        <div class="review-content-right">
          <div class="review-content-right-title">问卷信息</div>
          <div style="width: 80%" v-show="dataForm.reject_reason">
            <div class="review-content-right-title-title">驳回原因</div>
            <div class="review-content-right-person" style="margin-top: 15px">
              {{dataForm.reject_reason}}
            </div>
          </div>
          <div style="width: 80%">
            <div class="review-content-right-title-title">患者基本信息</div>
            <div class="review-content-right-person" style="margin-top: 15px">
              <!--            <div class="review-content-right-person-name">-->
              <!--              年龄 : <a-input-->
              <!--                class="inputWitch"-->
              <!--                v-model="dataForm.template_content.basic_patient_information.age"-->
              <!--            />-->
              <!--            </div>-->
              <div class="review-content-right-person-sex">
                住院编号 : <a-input
                  class="inputWitch"
                  v-model="dataForm.template_content.basic_patient_information.hospital_number"
              />
              </div>
              <a-button @click="examineBtn(dataForm.id,dataForm.template_content.basic_patient_information.hospital_number)" type="primary">检查</a-button>
            </div>
            <div class="review-content-right-person" style="margin-top: 15px">
              <!--            <div class="review-content-right-person-name">-->
              <!--              姓名 : <a-input-->
              <!--                class="inputWitch"-->
              <!--                v-model="dataForm.template_content.basic_patient_information.name"-->
              <!--            />-->
              <!--            </div>-->
              <div class="review-content-right-person-name">
                年龄 : <a-input
                  class="inputWitch"
                  v-model="dataForm.template_content.basic_patient_information.age"
              />
              </div>
              <div class="review-content-right-person-sex">
                性别 : <a-radio-group
                  v-model="dataForm.template_content.basic_patient_information.sex"
                  :options="questSex" />
              </div>
            </div>
            <div class="review-content-right-person">
              <div style="margin-left: 3%">既往史 :</div>
              <div style="width: 78%;margin-left: 5px">
                <a-textarea
                    v-model="dataForm.template_content.basic_patient_information.past_history"
                    style="width: 100%"
                    placeholder=""
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </div>
            </div>
          </div>
          <!--        待完善既往史-->
          <div style="display: flex;width: 100%;margin-top: 20px">
            <div style="margin-left: 10px;width:95px">待完善既往史 :</div>
            <div style="margin-left: 5px;width: 80%">
              <div class="toBeMessage">
                <div class="font">高血压</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_history_improved.hypertension"
                    :options="questWaitGao" />
              </div >
              <div class="toBeMessage">
                <div class="font">冠心病</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_history_improved.coronary_heart_disease"
                    :options="questWaitGuan" />
              </div>
              <div class="toBeMessage">
                <div class="font">心脏病</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_history_improved.heart_disease"
                    :options="questWaitZang"
                />
              </div>
              <div class="toBeMessage">
                <div class="font">心房颤动/心房扑动/房扑/房颤</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_history_improved.Atrial_fibrillation"
                    :options="questWaitXin"/>
              </div>
              <div class="toBeMessage">
                <div class="font">窦速/窦性心动过速</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_history_improved.Sinus_velocity"
                    :options="questWaitDou" />
              </div>
              <div class="toBeMessage">
                <div>诊断 :</div>
                <div style="width: 78%;margin-left: 5px">
                  <a-textarea
                      style="width: 100%"
                      v-model="dataForm.template_content.basic_patient_information.diagnosis"
                      placeholder=""
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--        待完善诊断-->
          <div style="display: flex;width: 100%;margin-top: 20px">
            <div style="margin-left: 10px;width:95px">待完善诊断 :</div>
            <div style="margin-left: 5px;width: 80%">
              <div class="toBeMessage">
                <div class="font">冠状动脉粥样硬化性心脏病/急 性冠脉综合征/急性ST段抬高型 心肌梗死/急性非ST段抬高型心 肌梗死/心绞痛/冠心病</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_Diagnosis_improved.Coronary_artery"
                    :options="questWaitGuanZhuang" />
              </div >
              <div class="toBeMessage">
                <div class="font">高血压</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_Diagnosis_improved.hypertension"
                    :options="questWaitGaoxue"/>
              </div>
              <div class="toBeMessage">
                <div class="font">心衰/心力衰竭/急性心力衰竭</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_Diagnosis_improved.Heart_failure"
                    :options="questWaitxinshuai" />
              </div>
              <div class="toBeMessage">
                <div class="font">心房颤动/心房扑动/房扑/房颤</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_Diagnosis_improved.Atrial_fibrillation"
                    :options="questWaitXinFang" />
              </div>
              <div class="toBeMessage">
                <div class="font">窦速/窦性心动过速</div>
                <a-radio-group
                    class="selectBtn"
                    v-model="wait_Diagnosis_improved.Sinus_velocity"
                    :options="questWaitDouSu" />
              </div>
            </div>
          </div>
          <!--        手术记录单-->
          <div style="font-weight: bold;padding-left: 30px;margin-top: 30px;">手术记录单 :</div>
          <div style="display: flex;width: 100%;margin-top: 20px">
            <div style="margin-left: 5px;width: 100%">
              <div class="betoMessage">
                <div class="font">ASA分级 :</div>
                <a-input
                    class="inputShu"
                    v-model="dataForm.template_content.surgical_record.ASA"
                />
              </div >
              <div class="betoMessage">
                <div class="font">术前诊断 :</div>
                <a-input
                    class="inputShu"
                    v-model="dataForm.template_content.surgical_record.preoperative_diagnosis"
                />
              </div>
              <div class="betoMessage">
                <div class="font">实施手术/拟行手术 :</div>
                <a-input
                    class="inputShu"
                    v-model="dataForm.template_content.surgical_record.operation"
                />
              </div>
              <div class="betoMessage">
                <div class="font">麻醉方式/麻醉方法 :</div>
                <a-input
                    class="inputShu"
                    v-model="dataForm.template_content.surgical_record.anaesthetization"
                />
              </div>
              <div class="betoMessage">
                <div class="font">爱络用药使用时机 :</div>
              </div>
              <div class="betoMessage">
                <a-checkbox-group
                    style="width: 60%;margin-left: 10%;"
                    v-model="dataForm.template_content.surgical_record.medication">
                  <a-row>
                    <a-col :span="24">
                      <a-checkbox value="插管(插管前1.5~3min,0.5mg/kg推注)">
                        插管（插管前1.5~3min，0.5mg/kg推注)
                      </a-checkbox>
                    </a-col>
                    <a-col :span="24" style="margin-top: 15px">
                      <a-checkbox value="拔管(插管前1.5~3min,0.5mg/kg推注)">
                        拔管（插管前1.5~3min，0.5mg/kg推注）
                      </a-checkbox>
                    </a-col>
                    <a-col :span="24" style="margin-top: 15px">
                      <a-checkbox value="术中持续使用">
                        术中持续使用（术中全程，25-200μg/kg/min持续输注，可根据心率血压调整）
                      </a-checkbox>
                    </a-col>
                    <a-col :span="24" style="margin-top: 15px">
                      <a-checkbox value="术中单次使用">
                        术中单次使用（1mg/kg推注，30秒，即刻控制心律失常或高血压）
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </div>
              <div class="betoMessage">
                <div class="font">手术时长（分钟) :</div>
                <a-input
                    class="inputShu"
                    v-model="dataForm.template_content.surgical_record.shoushu_time"
                />
              </div>
              <div class="betoMessage">
                <div class="font">艾司洛尔（mg）:</div>
                <a-input
                    class="inputShu"
                    v-model="dataForm.template_content.surgical_record.esmolol"
                />
              </div>
              <div>
                <div class="mazui">
                  <div class="font">麻醉开始 :</div>
                </div>
                <!--  1-->
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">心率/脉搏（pulse）</div>
                  <div class="mazui-input-one">收缩压</div>
                  <div class="mazui-input-one">舒张压</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">
                    <a-input
                        style="width: 80%"
                        placeholder="请输入"
                        v-model="dataForm.template_content.surgical_record.mazui.heart_rate"
                    />
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.mazui.systolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.mazui.diastolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                </div>
                <!--  2-->
                <div class="mazui">
                  <div class="font">插管 :</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">心率/脉搏（pulse）</div>
                  <div class="mazui-input-one">收缩压</div>
                  <div class="mazui-input-one">舒张压</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.intubation.heart_rate"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.intubation.systolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.intubation.diastolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                </div>
                <!--  3-->
                <div class="mazui">
                  <div class="font">手术开始 :</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">心率/脉搏（pulse）</div>
                  <div class="mazui-input-one">收缩压</div>
                  <div class="mazui-input-one">舒张压</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.surgery_begins.heart_rate"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.surgery_begins.systolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.surgery_begins.diastolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                </div>
                <!--  4-->
                <div class="mazui">
                  <div class="font">手术结束 :</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">心率/脉搏（pulse）</div>
                  <div class="mazui-input-one">收缩压</div>
                  <div class="mazui-input-one">舒张压</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.surgery_is_over.heart_rate"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.surgery_is_over.systolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.surgery_is_over.diastolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                </div>
                <!--  5-->
                <div class="mazui">
                  <div class="font">拔管 :</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">心率/脉搏（pulse）</div>
                  <div class="mazui-input-one">收缩压</div>
                  <div class="mazui-input-one">舒张压</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.extubation.heart_rate"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.extubation.systolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.extubation.diastolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                </div>
                <!--  6-->
                <div class="mazui">
                  <div class="font">出手术室 :</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">心率/脉搏（pulse）</div>
                  <div class="mazui-input-one">收缩压</div>
                  <div class="mazui-input-one">舒张压</div>
                </div>
                <div class="mazui-input">
                  <div class="mazui-input-one" style="margin-left: 15px">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.out_of_operating_room.heart_rate"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.out_of_operating_room.systolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                  <div class="mazui-input-one">
                    <a-input
                        style="width: 80%"
                        v-model="dataForm.template_content.surgical_record.out_of_operating_room.diastolic_blood_pressure"
                        placeholder="请输入"/>
                  </div>
                </div>
                <div class="mazui">
                  <div class="font">伴随用药 :</div>
                </div>
                <div class="betoMessage">
                  <a-checkbox-group
                      style="width: 60%;margin-left: 10%;"
                      v-model="dataForm.template_content.surgical_record.concomitant_medication"
                  >
                    <a-row>
                      <a-col :span="24">
                        <a-checkbox value="乌拉地尔">
                          乌拉地尔
                        </a-checkbox>
                      </a-col>
                      <a-col :span="24" style="margin-top: 15px">
                        <a-checkbox value="右美托嘧啶">
                          右美托嘧啶
                        </a-checkbox>
                      </a-col>
                      <a-col :span="24" style="margin-top: 15px">
                        <a-checkbox value="丙泊酚">
                          丙泊酚
                        </a-checkbox>
                      </a-col>
                      <a-col :span="24" style="margin-top: 15px">
                        <a-checkbox value="芬太尼/舒芬太尼/瑞芬太尼/阿芬太尼">
                          芬太尼/舒芬太尼/瑞芬太尼/阿芬太尼
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <a-modal
          v-model="visible"
          width="700px"
          title="审批">
        <template slot="footer">
          <a-button @click="quxiaoModel">
            取消
          </a-button>
          <a-button type="primary"  @click="overruleModel">
            确定
          </a-button>
        </template>
        <div style="width: 100%;display: flex">
          <div style="width:20%;text-align: right;font-weight: bold;color: black">驳回原因 :</div>
          <a-checkbox-group
              v-model="checkboxVal"
              @change="checkbox_group_onChange"
              style="margin-left: 10px;width: 600px;">
            <a-row>
              <a-col style="margin-bottom: 8px" :span="24" v-for="(overRule,index) in overRules">
                <a-checkbox
                    :value="overRule">
                  {{overRule}}
                </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </div>
        <a-textarea
            style="width:500px;margin-left: 120px;margin-top: 10px;"
            v-model="overruleValue"
            placeholder="请输入其他驳回原因"
            :auto-size="{ minRows: 6, maxRows: 10 }"
        />
      </a-modal>
      <div class="footer-div">
        <div class="footer-div-btn">
          <a-button @click="returnBtn">返回上一层</a-button>
          <a-button
              v-show="!passValue"
              style="margin-left: 10px"
              @click="unPassBtn">驳回</a-button>
          <a-button
              v-show="!passValue"
              @click="passBtn"
              style="margin-left: 10px"
              type="primary">通过</a-button>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>

// import {caseBuried, getCaseMessage, putCaseGo, putSex, putSexPass} from "../../service/api";
import moment from "moment";
import {
  getCaseMessage,
  getCaseMessage_course,
  putCaseGo,
  putSex,
  TemplateIdRepeatHospitalNumber
} from "../../../service/case_api";

export default {
  data(){
    return{
      overRules:[],
      checkboxVal:[],
      lenghtImage:'',
      spinning:false,
      dateFormatList:'YYYY-MM-DD',
      router_id:'',
      sexData:'',
      caseDetail:[],
      value:1,
      overruleValue:'',
      valueAge:20,
      visible:false,
      srcTest:'https://questionnaire.yunicu.com/#/esmolol/m',
      questSex : [
        { label: '男', value: '男' },
        { label: '女', value: '女' },
      ],
      Sex : [
        { label: '男', value: 1 },
        { label: '女', value: 2 },
      ],
      valueSex:'',
      //既往史
      // valueJwsh:'',
      //高血压
      questWaitGao : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      valueWaitGao:'yes',
      //冠心病
      questWaitGuan : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      valueWaitGuan:'no',
      //心脏病
      questWaitZang : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      valueWaitZang:'yes',
      //心房颤动/心房扑动/房扑/房颤
      questWaitXin : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      valueWaitXin:'no',
      //窦速/窦性心动过速
      questWaitDou : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      valueWaitDou:'yes',


      //待完善诊断
      //冠状动脉粥样硬化性心脏病/急 性冠脉综合征/急性ST段抬高型 心肌梗死/急性非ST段抬高型心 肌梗死/心绞痛/冠心病
      questWaitGuanZhuang : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      // valueWaitGuanZhuang:'yes',
      //高血压
      questWaitGaoxue : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      // valueWaitGaoxue:'no',
      //心衰/心力衰竭/急性心力衰竭
      questWaitxinshuai : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      // valueWaitxinshuai:'yes',
      //心房颤动/心房扑动/房扑/房颤
      questWaitXinFang : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      // valueWaitXinFang:'no',
      //窦速/窦性心动过速
      questWaitDouSu : [
        { label: '是', value: '是' },
        { label: '否', value: '否' },
      ],
      // valueWaitDouSu:'yes',
      //待完善既往史
      wait_history_improved:{
        "hypertension":"否",
        "coronary_heart_disease": "否",
        "heart_disease": "否",
        "Atrial_fibrillation": "否",
        "Sinus_velocity":"否"
      },
      //待完善诊断
      wait_Diagnosis_improved:{
        "Coronary_artery":"否",
        "hypertension": "否",
        "Heart_failure": "否",
        "Atrial_fibrillation": "否",
        "Sinus_velocity":"否"
      },
      //审核状态
      state:'',
      ageData:'',
      //  模拟数据
      dataForm: {
        "id": '',
        "memberName": "",
        "memberHospitalName": "",
        "patientSex": null,
        "patientAge": "0",
        "template_content": {
          "surgical_record": {
            "anaesthetization": "",
            //插管
            "intubation": {
              "heart_rate": "",
              "systolic_blood_pressure": "",
              "diastolic_blood_pressure": ""
            },
            //爱络用药使用时机
            "medication": [],
            "show_image_list": [],
            //手术结束
            "surgery_is_over": {
              "heart_rate": "",
              "systolic_blood_pressure": "",
              "diastolic_blood_pressure": ""
            },
            "ASA": "",
            //拔管
            "extubation": {
              "heart_rate": "",
              "systolic_blood_pressure": "",
              "diastolic_blood_pressure": ""
            },
            //出手术室
            "out_of_operating_room": {
              "heart_rate": "",
              "systolic_blood_pressure": "",
              "diastolic_blood_pressure": ""
            },
            //伴随用药
            "concomitant_medication": [
              "",
              "",
              "",
              ""
            ],
            //麻醉开始
            "mazui": {
              "heart_rate": "",
              "systolic_blood_pressure": "",
              "diastolic_blood_pressure": ""
            },
            //手术开始
            "surgery_begins": {
              "heart_rate": "",
              "systolic_blood_pressure": "",
              "diastolic_blood_pressure": ""
            },
            "shoushu_time": "",
            "operation": "",
            "preoperative_diagnosis": "",
            //爱斯洛儿
            "esmolol": "",
            "image_list": [
              {
                "uid": 1646634283528,
                "name": "会员.png",
                "url": "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/a385652d-f294-451d-bae1-982f41d47bb8.png",
                "status": "success"
              }
            ]
          },
          "basic_patient_information": {
            "hospital_number": "",
            "sex": "",
            "name": "",
            "past_history": "",
            "diagnosis": "",
            "age": "",
            "show_image_list": [],
            "image_list": [
              {
                "uid": 1646634252470,
                "name": "烤鸡.png",
                "url": "https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/images/74945f8c-03e4-4c10-adcf-6c9f6fb96bd3.png",
                "status": "success"
              }
            ]
          }
        },
        "created_time": ""
      }
    }
  },
  created() {
  },
  mounted() {
    this.getRouter_id()
    this.getCase()
    this.getCaseCourse()
  },
  computed:{
    passValue(){
      return this.$store.state.passBtn
    },
  },
  methods:{
    //获取url路径id
    getRouter_id(){
      const id = this.$route.query.id
      this.router_id = id
    },
    checkbox_group_onChange(){
      this.overruleValue = this.checkboxVal.join(';')
    },
    //获取病例详情
    async getCase(){
      //  获取路径中的id
      const id = this.router_id
      const reponce = await getCaseMessage(id)
      if(reponce.code === 0){
        //获取接口中的状态
        // 审核状态：PENDING：待审核 ;PASSED：已通过  ;DENIED：已拒绝
        this.state = reponce.data.audit_status
        //给患者性别赋初值
        this.sexData = reponce.data.patientSex
        //查看接口中有没有   待完善既往史  和  待完善诊断
        const josnHistory = reponce.data.template_content.surgical_record.wait_history_improved
        const josnDiagnosis = reponce.data.template_content.surgical_record.wait_Diagnosis_improved
        //判断接口中有没有待完善既往史 待完善诊断
        if(josnHistory !== '' && josnHistory !== undefined && josnHistory !== null
            && josnDiagnosis !== '' && josnDiagnosis !== undefined && josnDiagnosis !== null){
          //有就赋值，显示在界面上
          this.wait_history_improved = josnHistory
          this.wait_Diagnosis_improved = josnDiagnosis
          this.caseDetail = reponce.data
          this.dataForm = this.caseDetail
        }else {
          //没有就往表单数组中追加数组
          const sum = reponce.data
          const json1 =  this.wait_Diagnosis_improved
          const json2 = this.wait_history_improved
          //追加数组
          sum.template_content.surgical_record.wait_Diagnosis_improved = json1
          sum.template_content.surgical_record.wait_history_improved = json2
          this.caseDetail = sum
          this.dataForm = this.caseDetail
        }
        this.lenghtImage = this.dataForm.coding_image_list.length
      }else {
        this.$message.warning("获取接口失败，请联系管理员！！")
      }
    },
    //获取驳回原因
    async getCaseCourse() {
      let id = this.$route.query.templeteId
      const response = await getCaseMessage_course(id)
      if(response.code === 0){
        this.overRules = response.data.remark
      }else {
        this.$message.warning(response.message)
      }
    },
    //修改性别和年龄
    async putSex(sexData,ageData){
      const id = this.router_id
      const data = {
        "patientSex":parseInt(sexData),
        "birthday":ageData
      }
      const reponce = await putSex(id,data)
      if(reponce.code === 0){
        this.$message.success('修改成功！'+reponce.message)
        await this.getCase()
      }else {
        this.$message.warning(reponce.message)
      }
    },
    //性别单选框
    onChange() {
      const sex = this.dataForm.patientSex
      this.sexData = sex
    },
    //患者年龄
    ageChange(date){
      const dataTime = moment(date).format('YYYY-MM-DD')
      this.ageData = dataTime
    },
    // 保存患者信息
    preservation(){
      const sex = parseInt(this.sexData)
      const age = this.ageData
      if(sex!== '' && sex !== null && sex !== undefined ||
          age!== '' && age !== null && age !== undefined){
        switch (sex){
          case 1:
            this.putSex(1,age)
            break;
          case 2:
            this.putSex(2,age)
            break;
          default:
            break;
        }
      }
    },
    // 保存上面修改的信息
    async saveBtn() {
      this.spinning = true
      const sum = this.dataForm
      //赋值
      sum.template_content.surgical_record.wait_Diagnosis_improved = this.wait_Diagnosis_improved
      sum.template_content.surgical_record.wait_history_improved = this.wait_history_improved
      const id = this.router_id
      const reject_reason = this.overruleValue
      //审核状态：PENDING：待审核 ; PASSED：已通过 ;DENIED：已拒绝
      const data = {
        "template_content": sum.template_content,
        // "audit_status":"DENIED",
        // "reject_reason":reject_reason
      }
      const reponce = await putSexPass(id, data)
      if (reponce.code === 0) {
        this.$message({
          showClose: true,
          offset: 2100,
          message: '已保存' + reponce.message,
          type: 'success'
        });
      } else {
        this.$message({
          showClose: true,
          offset: 2100,
          message: '出错，请联系管理员！！' + reponce.message,
          type: 'success'
        });
      }
      //  保存下操作者id
      let caseData = {
        content_id:this.$route.query.id,
        module_function:'保存病例',
        modular:'病例审核',
        user_id:this.$route.query.user_id,
      }
      const  localityData = await caseBuried(caseData)
      if(localityData.code !== 0){
        this.$message.warning('失败，请联系管理员！'+ localityData.message)
      }
      this.spinning = false
    },
    //返回上一层
    returnBtn() {
      window.history.back()
    },
    unPassBtn(){
      this.visible = true
    },
    async passBtn() {
      const sum = this.dataForm
      //赋值
      sum.template_content.surgical_record.wait_Diagnosis_improved = this.wait_Diagnosis_improved
      sum.template_content.surgical_record.wait_history_improved = this.wait_history_improved
      const reject_reason = this.overruleValue
      //审核状态：PENDING：待审核 ; PASSED：已通过 ;DENIED：已拒绝
      const data = {
        "template_content": sum.template_content,
        "audit_status":"PASSED",
      }
      let id = this.$route.query.id
      let templeteIdY = this.$route.query.templeteId
      const reponse = await putCaseGo(id,data,templeteIdY)
      if (reponse.code === 0) {
        this.$message.success('已通过！' + reponse.message)
        //刷新
        await this.getCase()
      } else {
        this.$message.error("失败" + reponse.message)
      }
    },

    quxiaoModel() {
      this.visible = false;
    },
    //驳回确定按钮
    async overruleModel() {
      const sum = this.dataForm
      //赋值
      sum.template_content.surgical_record.wait_Diagnosis_improved = this.wait_Diagnosis_improved
      sum.template_content.surgical_record.wait_history_improved = this.wait_history_improved
      const reject_reason = this.overruleValue
      //审核状态：PENDING：待审核 ; PASSED：已通过 ;DENIED：已拒绝
      const data = {
        "template_content": sum.template_content,
        "audit_status":"DENIED",
        "reject_reason":reject_reason
      }
      let id = this.$route.query.id
      let templeteIdY = this.$route.query.templeteId
      const response = await putCaseGo(id, data,templeteIdY)
      if (response.code === 0) {
        this.$message.success('已驳回！' + response.message)
      } else {
        this.$message.error("失败，" + response.message)
      }
      this.visible = false
    },
    async examineBtn(id,member) {
      const reponse = await TemplateIdRepeatHospitalNumber(id,member)
      if (reponse.code === 0) {
        this.$message.success(reponse.message)
      } else {
        this.$message.error( reponse.message)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.review-title{
  font-size: 22px;
  font-weight: bold;
  color: black;
  height:100px;
  line-height: 80px;
  padding-left: 30px;
  border-bottom: 10px solid #f5f5f5;
}
.review-doc{
  height:120px;
  line-height: 50px;
  border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-doc-title{
    font-size: 15px;
    color: black;
    font-weight: bold;
    padding-left: 30px;
    border-bottom: 2px solid #f5f5f5;
  }
  .review-doc-content{
    width: 100%;
    display: flex;
    color: black;
    .review-doc-content-font{
      width: 30%;
      padding-left: 30px;
      color: black;
    }
  }
}

.review-huan{
  height:150px;
  line-height: 50px;
  border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-huan-title{
    padding-left: 30px;
    border-bottom: 2px solid #f5f5f5;
    display: flex;
    color: black;
    .review-huan-title-huanzhe{
      font-size: 15px;
      color: black;
      font-weight: bold;
      width: 85%;
    }
  }
  .review-huan-content{
    width: 100%;
    display: flex;
    color: black;
    .review-huan-content-font{
      width: 30%;
      padding-left: 30px;
      padding-top: 15px;
      display: flex;
      color: black;
    }
    .review-huan-content-font-two{
      display: flex;
      width: 30%;
      padding-left: 30px;
      padding-top: 15px;
      color: black;
    }
  }
}
.review-content{
  height: auto;
  display: flex;
  //border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-content-left{
    width: 50%;
    //height: 100%;
    min-height: 2000px;
    border-right: 10px solid #f5f5f5;
    color: black;
    .review-content-left-title{
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      color: black;
      font-weight: bold;
      padding-left: 30px;
      border-bottom: 2px solid #f5f5f5;
    }
    .review-content-left-font{
      font-weight: bold;
      padding-left: 30px;
      color: black;
      margin-bottom: 15px;
    }
  }
  .review-content-right{
    width: 50%;
    height: 100%;
    color: black;
    .review-content-right-title{
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      color: black;
      font-weight: bold;
      padding-left: 30px;
      border-bottom: 2px solid #f5f5f5;
    }
    .review-content-right-title-title{
      font-weight: bold;
      padding-left: 30px;
      margin-top: 30px;
      color: black;
    }
    .review-content-right-person{
      display: flex;
      margin-bottom: 15px;
      width: 100%;
      margin-left:80px;
      color: black;
      .review-content-right-person-name{
        width: 50%;
        margin-left: 5%;
        color: black;
      }
      .review-content-right-person-sex{
        width: 50%;
      }
    }
    .toBeMessage{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      .font{
        width: 50%;
        color: black;
      }
      .selectBtn{
        width: 50%;
        color: black;
      }
    }
    .betoMessage{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      .font{
        width: 150px;
        text-align: right;
        color: black;
      }
      .inputShu{
        width: 60%;
        margin-left: 5px;
        color: black;
      }
    }
    .mazui{
      margin-bottom: 15px;
      width: 100%;
      .font{
        margin-left: 5%;
        color: black;
      }
    }
    .mazui-input{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      color: black;
      .mazui-input-one{
        width: 24%;
        text-align: center;
        color: black;
      }
    }
  }
}
.review-btn{
  height: 60px;
  line-height: 50px;
  margin-left: 80%;
  float: right;
  color: black;
}
.inputWitch{
  width: 60%;
  color: black;
}
.footer-div{
  width: 100%;
  height: 50px;
  background-color: #f0f2f5;
  position: fixed;
  bottom: 50px;
  border-bottom: 1px solid #fff;
  .footer-div-btn{
    display: flex;justify-content: right;
    width: 82%;
    margin-top: 8px;
  }
}
</style>